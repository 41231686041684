import { useAuth0 } from '@auth0/auth0-react';
import { Card, Divider, FocusStyleManager, H2, H5, Icon } from '@blueprintjs/core';
import { observer, useLocalObservable } from 'mobx-react-lite';
import Link from 'next/link';
import { useEffect } from 'react';
import Layout from '../common/components/Layout';
import { useAuthStore } from '../features/api/AuthStoreProvider';
FocusStyleManager.onlyShowFocusOnTabs();

export default observer(function Home() {
    const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const store = useLocalObservable(() => ({
        categories: 0,
        programs: 0,
        routines: 0,
        exercises: 0,
        users: 0,
        firmware: 0,
        coaches: 0,
        bypasses: 0,
        bans: 0,
        featured: 0,
    }));

    const authStore = useAuthStore();

    useEffect(() => {
        if (authStore.isAuthenticated) {
            authStore.api.get('/admin/categories').then(({ data }) => (store.categories = (data as { length: number }).length));
            authStore.api.get('/admin/coaches').then(({ data }) => (store.coaches = (data as { length: number }).length));
            authStore.api.get('/admin/programs').then(({ data }) => (store.programs = (data as { length: number }).length));
            authStore.api.get('/admin/routines').then(({ data }) => (store.routines = (data as { length: number }).length));
            authStore.api.get('/exercises').then(({ data }) => (store.exercises = (data as { length: number }).length));
            authStore.api.get('/users').then(({ data }) => (store.users = (data as { length: number }).length));
            authStore.api.get('/firmware').then(({ data }) => (store.firmware = (data as { length: number }).length));
            authStore.api.get('/admin/bypasses').then(({ data }) => (store.bypasses = (data as { length: number }).length));
            authStore.api.get('/admin/bans').then(({ data }) => (store.bans = (data as { length: number }).length));
            authStore.api.get('/user/featured').then(({ data }) => (store.featured = (data as { length: number }).length));
        }
    }, [authStore.isAuthenticated, authStore.loaded]);

    return (
        <Layout>
            <H2 style={{ marginTop: 16 }}>Welcome back, {user?.given_name}</H2>
            <Divider style={{ marginTop: 16 }} />
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Link href="/categories">
                    <Card interactive style={{ margin: 8, width: '22%' }}>
                        <H5>
                            <Icon icon="th" /> Categories
                        </H5>
                        <H2>{store.categories}</H2>
                    </Card>
                </Link>
                <Link href="/coaches">
                    <Card interactive={true} style={{ margin: 8, width: '22%' }}>
                        <H5>
                            <Icon icon="person" /> Coaches
                        </H5>
                        <H2>{store.coaches}</H2>
                    </Card>
                </Link>
                <Link href="/programs">
                    <Card interactive style={{ margin: 8, width: '22%' }}>
                        <H5>
                            <Icon icon="calendar" /> Programs
                        </H5>
                        <H2>{store.programs}</H2>
                    </Card>
                </Link>
                <Link href="/routines">
                    <Card interactive={true} style={{ margin: 8, width: '22%' }}>
                        <H5>
                            <Icon icon="film" />
                            <a
                                aria-hidden="true"
                                data-testid="routineLink"
                                href="/routines"
                                className="unstyle">
                                {' '}
                                Routines
                            </a>
                        </H5>
                        <H2>{store.routines}</H2>
                    </Card>
                </Link>
                <Link href="/exercises">
                    <Card interactive={true} style={{ margin: 8, width: '22%' }}>
                        <H5>
                            <Icon icon="learning" /> Exercises
                        </H5>
                        <H2>{store.exercises}</H2>
                    </Card>
                </Link>
                <Link href="/users">
                    <Card interactive={true} style={{ margin: 8, width: '22%' }}>
                        <H5>
                            <Icon icon="user" /> Users
                        </H5>
                        <H2>{store.users}</H2>
                    </Card>
                </Link>
                <Link href="/firmware">
                    <Card interactive={true} style={{ margin: 8, width: '22%' }}>
                        <H5>
                            <Icon icon="build" /> Firmware
                        </H5>
                        <H2>{store.firmware}</H2>
                    </Card>
                </Link>
                <Link href="/devices">
                    <Card interactive={true} style={{ margin: 8, width: '22%' }}>
                        <H5>
                            <Icon icon="unlock" /> Devices
                        </H5>
                        <H2>{store.bypasses + store.bans}</H2>
                    </Card>
                </Link>
                <Link href="/featured">
                    <Card interactive={true} style={{ margin: 8, width: '22%' }}>
                        <H5>
                            <Icon icon="clean" /> Featured
                        </H5>
                        <H2>{store.featured}</H2>
                    </Card>
                </Link>
            </div>
        </Layout>
    );
});